import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="220716" />
      <div className="text-center pb-4">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/sketch_220716.jpg"
          width={500}
          quality={95}
          />
      </div>
      <p>I was watching the tv series of travel documentary in Iceland. The guys visited the central city of Reykjavík, and the church Hallgrimskirkja solemnly standing in the city was outstanding.</p>
      <p>Soon out of the city, the lasting road surrounded by great nature. Beside the road, the landscape covered with snow was marvelous. I wish one day I can visit and see them with my eyes.</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
